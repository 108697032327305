import * as React from "react";
import Grid from "@mui/material/Grid";
import {Button,TextField,Dialog,DialogActions,DialogContent,DialogTitle,} from "@mui/material";
import { FormControlLabel, InputAdornment } from "@mui/material";
import { Radio, RadioGroup, Checkbox } from "@mui/material";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Wrapper } from "@googlemaps/react-wrapper";
import * as Const from "../Const.tsx";
import { PublicParams } from "../forms/MainForm.tsx";
import dayjs from 'dayjs';
import './CustomerDialog.css';
import { CommonUtil } from '../CommonUtil.tsx';
import * as Models from "../Models.tsx";
import { Coordinate, detectAlert } from '../components/PlanTable.tsx'

//マーカー画像
import MarkerImageActive from "../images/markers/marker-icon-2x-red-fixed.png";
/**
 * 利用者情報編集登録ダイアログ
 * @returns
 */
export function CustomerDialog({ isAddDialog }) {
  //訪問計画(共通パラメータ)
  const commonData = React.useContext(PublicParams);
  const contextMenu = React.useContext(Coordinate);

  /**
   * Map描画用
   * @param status
   * @returns
   */
  const render = (status) => {
    return <h1>{status}</h1>;
  };

  let dialogTitle;
  let customerCode;
  let c;
  let splitName;
  let customerColor;
  let defaultColor = '';

  if (isAddDialog) {
    dialogTitle = "利用者追加";
  }else{
    dialogTitle = "利用者編集";
    //選択中の編集する利用者を特定
    const activeVisitId = commonData.activeVisitIds[0].id;
    const visit = commonData.visitList.find((v) => v.id == activeVisitId);
    c = commonData.customerList.find((c) => c.code === visit.customerCode); 
    //苗字と名前で分割
    splitName = c.name.split(' ');
    //色
    customerColor = '#' + CommonUtil.rgbToString(c.color);
  }
  /**
   * ダイアログを閉じる
   */
  const handleClose = () => {
    commonData.setDialogName(null);
    if(isAddDialog){
      setCustomer(initialCustomerState);
    }
  }; 

  const initialCustomerState = isAddDialog ? {
    code: null,
    firstName: '',
    lastName: '',
    address: '',
    lat: null, 
    lng: null,
    color: Const.BlockColors.WHITE.toString(),
    visitPerWeek: 1,
    requireTwoStaffs: 0,
    requireSex: 0,
    specifiedTimes: 0,
    requireTime: [
      { startTime: null, endTime: null },
      { startTime: null, endTime: null },
      { startTime: null, endTime: null },
      { startTime: null, endTime: null },
      { startTime: null, endTime: null },
      { startTime: null, endTime: null },
    ],
    others: ''
  } : {
    firstName: splitName[0],
    lastName: splitName[1],
    address: c.address,
    lat: c.lat,
    lng: c.lng,
    color: customerColor,
    visitPerWeek: c.visitPerWeek,
    requireTwoStaffs: c.requireTwoStaffs,
    requireSex: c.requireSex,
    requireTime: c.requireTime,
    specifiedTimes: c.specifiedTimes,
    others: c.others
  };
 
  const [customer, setCustomer] = React.useState(initialCustomerState);
  //住所エラー
  const [isAddressError, setIsAddressError] = React.useState(false);
  //その他の色のボタンの選択状態
  const [isOtherColor, setIsOtherColor] = React.useState(false);
  //時刻を指定するのチェック状態
  const [requireTimeChecked, setRequireTimeChecked] = React.useState(false);

  if(isAddDialog){
    //利用者コードが重複しているかのチェック
    customerCode = commonData.customerList.find((c) => c.code === customer.code);
  }

  if(!isAddDialog){
    
    //Const.BlockColorsの色をstringのリストに変換
    const blockColorValues = Object.values(Const.BlockColors) as string[];
    if (!blockColorValues.includes(customer.color)) {
      defaultColor = customer.color.replace('#','');
    }

    //Const.BlockColor以外の色を設定した時はダイアログを開いたときに「その他の色」の状態をON
    React.useEffect(() => {
      if (customer.color && !blockColorValues.includes(customer.color)) {
        setIsOtherColor(true);
      } 
    }, [customer.color]);

    //時間指定がある場合はチェックを入れる
    React.useEffect(() => {
      if(customer.specifiedTimes == 1){
        setRequireTimeChecked(true)
      }
    }, [requireTimeChecked]);
  }

  //住所が空欄でマーカーを移動したとき
  React.useEffect(() => {
    if(isAddDialog){
      if(customer.lat === null || customer.lng === null){
        return;
      }
    }
    if(customer.address === '' || customer.address === undefined){
      CommonUtil.updateAddress(customer.lat, customer.lng)
      .then((result) => {
        setCustomer(prevCustomer => ({
          ...prevCustomer,
          address: result.address,
          lat: result.lat,
          lng: result.lng
        }));
        setIsAddressError(result.isAddressError)
      })
      .catch((error) => {
        console.error('エラーが発生しました:', error);
      })
    }
  }, [customer.lat,customer.lng])

  //地図上のマーカーをドラック＆ドロップした時の緯度経度を更新
  const handleMarkerDragEnd = (latitude: number, longitude: number) => {
    setCustomer(prevCustomer => ({
      ...prevCustomer,
      lat: latitude,
      lng: longitude
    }));
  };

  //OKボタン
  const handleFormSubmit = async (e) => {

    const result = await CommonUtil.updateLatLng(customer.address,customer.lat,customer.lng);

    if(!customer.firstName || !customer.lastName || !customer.address || 
      !customer.lat || !customer.lng || !customer.color || result.isAddressError || customerCode) {
        alert('入力が正しくありません。');
        return;
      }
      if(requireTimeChecked && customer.requireTime.every(item => item.startTime === null && item.endTime === null)){
        alert('希望時刻がない場合は、「時刻を指定する」のチェックを外してください。');
        return;
      }

      const newCustomer : Models.Customer = {
        code: isAddDialog ? customer.code : c.code,
        name: customer.firstName + ' ' + customer.lastName,
        address : customer.address,
        matchLevel : null,
        matchLevelStr : null,
        lat: result.lat,
        lng: result.lng,
        color : CommonUtil.argbToRgb(customer.color.replace('#','')),
        visitPerWeek: customer.visitPerWeek,
        requireTwoStaffs: customer.requireTwoStaffs,
        requireSex: customer.requireSex,
        specifiedTimes : Number(requireTimeChecked),
        requireTime: customer.requireTime,
        others: customer.others,  
      };

      if(isAddDialog){

        const updatedCustomerList = [...commonData.customerList, newCustomer];
        commonData.setCustomerList(updatedCustomerList);
        
        const visitData = CommonUtil.calculateClickData(commonData,contextMenu);
        const addvisit: Models.Visit = {
          id: commonData.visitList.slice(-1)[0].id + 1,
          customerCode: customer.code,
          day: visitData.day,
          staffCode: commonData.staffList[visitData.staffCode].code,
          arrivalTime: null,
          startTime: Models.Time.createTime(visitData.startTime),
          endTime: Models.Time.createTime(visitData.endTime),
          warning: [],
          alert: [],
          partnerVisitId: null,
          unassinedId: null,
          isPrimaryStaff: false,
          isGeneralNursing: false,
          isTimeHighlighted: false
        };

        //訪問頻度が１回以上の場合に、残りは未割当にする
        const unassinedList = CommonUtil.getNewUnassignedVisits(commonData, customer.code, customer.visitPerWeek-1);
        const updatedVisitList = [...commonData.visitList, addvisit, ...unassinedList];
         //警告をセット
         const newCommonData = {
          ...commonData,
          visitList: [...updatedVisitList]
        };
        const alertSet: Models.AlertSet = detectAlert({visit: addvisit, customer: newCustomer}, newCommonData);
        addvisit.alert = alertSet.alertList;
        addvisit.warning = alertSet.warningList;

        commonData.setVisitList(updatedVisitList);
        //経路線を削除
        commonData.setDirectionsList(commonData.directionsList.filter(x => !((x.day == visitData.day && x.staffCode == commonData.staffList[visitData.staffCode].code))));

      }else{
        //訪問頻度の差
        const differenceVisitPerWeek = customer.visitPerWeek - c.visitPerWeek;

        //更新
        let updatedCustomerList = commonData.customerList.map(c => {
          if (c.code === newCustomer.code) {
            return {... newCustomer};
          }else {
            return c;
          }
        });
        commonData.setCustomerList(updatedCustomerList);

        let updatedVisitList = [...commonData.visitList];
        //今回の変更で影響があるコースを調べる(影響があるコースは経路情報をリセットする)
        const dependencyCourse: {day : number, staffCode: number}[] =[];
        if(differenceVisitPerWeek > 0) {
          //訪問頻度が増えた場合に、残りは未割当にする
          const unassinedList = CommonUtil.getNewUnassignedVisits(commonData, c.code, differenceVisitPerWeek);
          updatedVisitList = [...commonData.visitList, ...unassinedList];
        }else if(differenceVisitPerWeek < 0){
          //訪問頻度が減った場合に、その利用者をvisitListの後ろから消す
          const isUnassined = (x) => !(x.unassinedId === undefined || x.unassinedId === null);
          const targetVisit = updatedVisitList
            .map((v, index) => ({ index, id: v.id, customerCode: v.customerCode, day: v.day, staffCode: v.staffCode, unassinedId: v.unassinedId, partnerVisitId: v.partnerVisitId }))
            .filter(item => item.customerCode === c.code)
            .sort((a, b) => (isUnassined(a) ? -1 : isUnassined(b) ? 1 : b.index - a.index));  
            
            //targetVisitから２人訪問を１つの訪問として数える
            let count = CommonUtil.countDuplicatePairs(targetVisit);
            const deleteList = [];
            let confirmDelete = false;
           
            for (let i = 0; i < targetVisit.length; i++) {
              if (targetVisit[i].unassinedId === null) {
                // 割当済の訪問先を削除する場合、削除前に確認メッセージを出す
                confirmDelete = true;
              }
              if(targetVisit[i].partnerVisitId !== null){
                //2人訪問の場合は同時に削除する
                deleteList.push(targetVisit[i].id);
                deleteList.push(targetVisit[i + 1].id);
                dependencyCourse.push({day: targetVisit[i].day, staffCode: targetVisit[i].staffCode});
                dependencyCourse.push({day: targetVisit[i + i].day, staffCode: targetVisit[i + 1].staffCode});
                i++;
              }else{
                // 削除対象をdeleteListに追加
                deleteList.push(targetVisit[i].id);
                dependencyCourse.push({day: targetVisit[i].day, staffCode: targetVisit[i].staffCode});
              }
              if (--count === customer.visitPerWeek) break;
            }

            if (confirmDelete) {
              if(confirm('割当済の訪問先を削除します。よろしいですか？')){
                // 確認がOKなら削除実行
                updatedVisitList = commonData.visitList.filter(v => !deleteList.includes(v.id));
              }else{
                updatedCustomerList = [...commonData.customerList];
                commonData.setCustomerList(updatedCustomerList);
                e.preventDefault(); 
                return;
              }
            }else {
              updatedVisitList = commonData.visitList.filter(v => !deleteList.includes(v.id));
            }
            handleClose();
        }

        //警告をセット
        const newCommonData = {
          ...commonData,
          visitList: [...updatedVisitList],
          customerList: [...updatedCustomerList]
        };
        CommonUtil.updateAlert(newCommonData);
        commonData.setVisitList(updatedVisitList);
        
        //編集している利用者を影響があるコースに追加（住所・緯度経度が変更された時に経路線を更新するため）
        const sameCustomers = commonData.visitList.filter( v => v.customerCode === newCustomer.code);
        for (let i = 0; i < sameCustomers.length; i++) {
          dependencyCourse.push({day: sameCustomers[i].day, staffCode: sameCustomers[i].staffCode});
        }
        //影響があるコースは経路情報を削除する
        commonData.setDirectionsList(commonData.directionsList.filter(x => !dependencyCourse.some( c => c.day == x.day && c.staffCode == x.staffCode)));
      }
      handleClose();
  };

  return (
    <Dialog
      maxWidth='md'
      open={isAddDialog ? commonData.dialogName === Const.DialogNames.ADD_CUSTOMER_DIALOG : commonData.dialogName === Const.DialogNames.CUSTOMER_DIALOG}
      onKeyDown={(event) => {
        if(event.key === 'Enter'){
          event.preventDefault();
        }
      }}
      PaperProps={{
        component: 'form',
      }}
    >
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item container xs={5} sx={{padding: '5px'}}>
            <Grid item xs={4} sx={{padding: '5px'}}>
              {isAddDialog && (
                <TextField
                required
                label="利用者コード"
                value={isNaN(customer.code) ? '' : customer.code}
                size="small"
                helperText={customerCode !== undefined && <span style={{ fontSize: 9, display: 'block' }}>重複しています</span>}
                error={customerCode !== undefined}
                onChange={e => {
                  const value = parseInt(e.target.value);
                  setCustomer({ ...customer, code:isNaN(value) ? '' : value}); // 数値でない場合は空文字列にする
                }}
              />
              )}
              {!isAddDialog && (
                <TextField
                label="利用者コード"
                value={c.code}
                size="small"
                disabled
              />
              )}
            </Grid>
            <Grid item xs={3} sx={{paddingTop: '5px'}}>
            <TextField
              required
              label="氏名(姓)"
              value={customer.firstName}
              size="small"
              onChange={e => setCustomer({...customer,firstName:e.target.value})}
            />
            </Grid>
            <Grid item xs={3} sx={{paddingTop: '5px',paddingLeft: '2px'}}>
            <TextField
              required
              label="氏名(名)"
              value={customer.lastName}
              size="small"
              onChange={e => setCustomer({...customer,lastName:e.target.value})}
            />
            </Grid>
            <Grid item xs={12} sx={{padding: '5px'}}>
              <TextField
                required
                label="住所"
                value={customer.address}
                InputProps={{ style: { fontSize: 10 } }}
                size="small"
                fullWidth
                onChange={e => setCustomer({...customer,address:e.target.value})}
                onBlur={async () => {
                  const result = await CommonUtil.updateLatLng(customer.address,customer.lat,customer.lng);
                  setCustomer({...customer,address:result.address,lat:result.lat,lng:result.lng})
                  setIsAddressError(result.isAddressError)
                }}
                helperText={isAddressError && <span style={{ fontSize: 9, display: 'block' }}>正しい住所を入力してください</span>}
                error={isAddressError}
              />
            </Grid>
            <Grid item xs={6} sx={{padding: '5px'}}>
              <TextField
                label="緯度"
                size="small"
                value={customer.lat ?? ''}
                disabled
              />
            </Grid>
            <Grid item xs={6} sx={{padding: '5px'}}>
              <TextField
                label="経度"
                value={customer.lng ?? ''}
                size="small"
                disabled
              />
            </Grid>
            <Grid item xs={12}>
            <Wrapper apiKey={Const.GOOGLE_MAPS_API_KEY} render={render}>
              <MapBox>
                <Marker
                  position={{ lat: customer.lat, lng: customer.lng }}
                  icon={{
                    url: MarkerImageActive,
                    scaledSize: { width: 25, height: 41 },
                  }}
                  draggable={true}
                  onDragEnd={handleMarkerDragEnd}
                  isAddDialog={isAddDialog ? true : false}
                ></Marker>
              </MapBox>
            </Wrapper>
            </Grid>
          </Grid>
          <Grid item container xs={7}>
            <Grid item container alignItems="center" xs={12}>
              <Grid item xs={2} className='itemName'>訪問頻度</Grid>
              <Grid item xs={10}>
                <RadioGroup row value={customer.visitPerWeek} onChange={e => setCustomer({...customer,visitPerWeek:parseInt(e.target.value)})}>
                  <FormControlLabel label="1回" control={<Radio />} value="1" />
                  <FormControlLabel label="2回" control={<Radio />} value="2" />
                  <FormControlLabel label="3回" control={<Radio />} value="3" />
                  <FormControlLabel label="4回" control={<Radio />} value="4" />
                  <FormControlLabel label="5回" control={<Radio />} value="5" />
                  <FormControlLabel label="6回" control={<Radio />} value="6" />
                </RadioGroup>
              </Grid>
              </Grid>
            <Grid item container alignItems="center" xs={12} >
              <Grid item xs={2} className='itemName'>希望人数</Grid>
              <Grid item xs={10}>
                <RadioGroup row value={customer.requireTwoStaffs} onChange={e => setCustomer({...customer,requireTwoStaffs:parseInt(e.target.value)})}>
                  <FormControlLabel label="どちらでもよい" control={<Radio />} value="0"/>
                  <FormControlLabel label="できれば2人" control={<Radio />} value="50"/>
                  <FormControlLabel label="2人" control={<Radio />} value="100" />
                </RadioGroup>
              </Grid>
            </Grid>
            <Grid item container alignItems="center" xs={12} >
              <Grid item xs={2} className='itemName'>希望性別</Grid>
              <Grid item xs={10}>
                <RadioGroup row value={customer.requireSex} onChange={e => setCustomer({...customer,requireSex:parseInt(e.target.value)})}>
                  <FormControlLabel label="どちらでもよい" control={<Radio />} value="0"/>
                  <FormControlLabel label="女性希望" control={<Radio />} value="1"/>
                  <FormControlLabel label="男性希望" control={<Radio />} value="2"/>
                </RadioGroup>
              </Grid>
            </Grid>
            <Grid item container alignItems="center" xs={12}  >
              <Grid item xs={2} className='itemName'>希望時刻</Grid>
              <Grid item xs={10}>
                <FormControlLabel 
                label="時刻を指定する" 
                checked={customer.specifiedTimes == 1}
                onChange={() => {
                  setRequireTimeChecked(!requireTimeChecked);
                  setCustomer(prevCustomer => ({
                  ...prevCustomer,
                  specifiedTimes: prevCustomer.specifiedTimes === 1 ? 0 : 1
                  }));
                }}
                control={<Checkbox/>}/>
              </Grid>
              <Grid item xs={2}></Grid>
              <Grid item xs={10}>
                <Grid item container xs={12} alignItems="center" justifyContent="flex-start" sx={{marginTop:'1px'}}>
                  <Grid sx={{marginLeft:'7px'}}>月</Grid>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker 
                      value={customer.requireTime[0].startTime ? dayjs(customer.requireTime[0].startTime) : null} 
                      ampm={false} 
                      disabled={!requireTimeChecked}
                      slotProps={{ textField: { size: "small" } }}
                      sx={{ "& .MuiInputBase-input": { height: 10 } , width: 100 , margin: 0.3}}
                      onChange={(newValue) => {
                        setCustomer({...customer,requireTime:CommonUtil.changeVisitTime(newValue,0,true,customer)})
                      }}
                    />
                    </LocalizationProvider>
                    <Grid>～</Grid>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker 
                      value={customer.requireTime[0].endTime ? dayjs(customer.requireTime[0].endTime) : null} 
                      ampm={false} 
                      disabled={!requireTimeChecked}
                      slotProps={{ textField: { size: "small" } }}
                      sx={{ "& .MuiInputBase-input": { height: 10 } , width: 100 , margin: 0.3 }}
                      onChange={(newValue) => {
                        setCustomer({...customer,requireTime:CommonUtil.changeVisitTime(newValue,0,false,customer)})
                      }}
                    />
                    </LocalizationProvider>
                    <Grid><Button style={{ fontSize:9 }} variant="contained" disabled={!requireTimeChecked} onClick={() =>{ setCustomer({...customer,requireTime: CommonUtil.anyTimeClick(0,customer)})}}>いつでも可</Button></Grid>
                    <Grid><Button style={{margin :'2px' , fontSize:9 }} variant="contained" disabled={!requireTimeChecked} onClick={() =>{ setCustomer({...customer,requireTime: CommonUtil.aroundTimeClick(0,customer)})}}>前後10分</Button></Grid>
                  </Grid>
                <Grid item container xs={12} alignItems="center" justifyContent="flex-start" sx={{marginTop:'1px'}}>
                  <Grid sx={{marginLeft:'7px'}}>火</Grid>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker 
                      value={customer.requireTime[1].startTime ? dayjs(customer.requireTime[1].startTime) : null} 
                      ampm={false} 
                      disabled={!requireTimeChecked}
                      slotProps={{ textField: { size: "small" } }}
                      sx={{ "& .MuiInputBase-input": { height: 10 } , width: 100 , margin: 0.3}}
                      onChange={(newValue) => {
                        setCustomer({...customer,requireTime:CommonUtil.changeVisitTime(newValue,1,true,customer)})
                      }}
                    />
                    </LocalizationProvider>
                    <Grid>～</Grid>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker 
                      value={customer.requireTime[1].endTime ? dayjs(customer.requireTime[1].endTime) : null} 
                      ampm={false} 
                      disabled={!requireTimeChecked}
                      slotProps={{ textField: { size: "small" } }}
                      sx={{ "& .MuiInputBase-input": { height: 10 } , width: 100 , margin: 0.3}}
                      onChange={(newValue) => {
                        setCustomer({...customer,requireTime:CommonUtil.changeVisitTime(newValue,1,false,customer)})
                      }}
                    />
                    </LocalizationProvider>
                    <Grid><Button style={{ fontSize:9 }} variant="contained" disabled={!requireTimeChecked} onClick={() =>{ setCustomer({...customer,requireTime: CommonUtil.anyTimeClick(1,customer)})}}>いつでも可</Button></Grid>
                    <Grid><Button style={{margin :'2px', fontSize:9 }} variant="contained" disabled={!requireTimeChecked} onClick={() =>{ setCustomer({...customer,requireTime: CommonUtil.aroundTimeClick(1,customer)})}}>前後10分</Button></Grid>
                  </Grid>
                <Grid item container xs={12} alignItems="center" justifyContent="flex-start" sx={{marginTop:'1px'}}>
                  <Grid sx={{marginLeft:'7px'}}>水</Grid>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker 
                      value={customer.requireTime[2].startTime ? dayjs(customer.requireTime[2].startTime) : null} 
                      ampm={false} 
                      disabled={!requireTimeChecked}
                      slotProps={{ textField: { size: "small" } }}
                      sx={{ ".MuiInputBase-input": { height: 10 } , width: 100 , margin: 0.3}}
                      onChange={(newValue) => {
                        setCustomer({...customer,requireTime:CommonUtil.changeVisitTime(newValue,2,true,customer)})
                      }}
                    />
                    </LocalizationProvider>
                    <Grid>～</Grid>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker 
                      value={customer.requireTime[2].endTime ? dayjs(customer.requireTime[2].endTime) : null} 
                      ampm={false} 
                      disabled={!requireTimeChecked}
                      slotProps={{ textField: { size: "small" } }}
                      sx={{ ".MuiInputBase-input": { height: 10 } , width: 100 , margin: 0.3}}
                      onChange={(newValue) => {
                        setCustomer({...customer,requireTime:CommonUtil.changeVisitTime(newValue,2,false,customer)})
                      }}
                    />
                    </LocalizationProvider>
                    <Grid><Button style={{ fontSize:9 }} variant="contained" disabled={!requireTimeChecked} onClick={() =>{ setCustomer({...customer,requireTime: CommonUtil.anyTimeClick(2,customer)})}}>いつでも可</Button></Grid>
                    <Grid><Button style={{margin :'2px', fontSize:9 }} variant="contained" disabled={!requireTimeChecked} onClick={() =>{ setCustomer({...customer,requireTime: CommonUtil.aroundTimeClick(2,customer)})}}>前後10分</Button></Grid>
                  </Grid>
                <Grid item container xs={12} alignItems="center" justifyContent="flex-start" sx={{marginTop:'1px'}}>
                  <Grid sx={{marginLeft:'7px'}}>木</Grid>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker 
                      value={customer.requireTime[3].startTime ? dayjs(customer.requireTime[3].startTime) : null} 
                      ampm={false} 
                      disabled={!requireTimeChecked}
                      slotProps={{ textField: { size: "small" } }}
                      sx={{ ".MuiInputBase-input": { height: 10 } , width: 100 , margin: 0.3}}
                      onChange={(newValue) => {
                        setCustomer({...customer,requireTime:CommonUtil.changeVisitTime(newValue,3,true,customer)})
                      }}
                    />
                    </LocalizationProvider>
                    <Grid>～</Grid>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker 
                      value={customer.requireTime[3].endTime ? dayjs(customer.requireTime[3].endTime) : null} 
                      ampm={false} 
                      disabled={!requireTimeChecked}
                      slotProps={{ textField: { size: "small" } }}
                      sx={{ ".MuiInputBase-input": { height: 10 } , width: 100 , margin: 0.3}}
                      onChange={(newValue) => {
                        setCustomer({...customer,requireTime:CommonUtil.changeVisitTime(newValue,3,false,customer)})
                      }}
                    />
                    </LocalizationProvider>
                    <Grid><Button style={{ fontSize:9 }} variant="contained" disabled={!requireTimeChecked} onClick={() =>{ setCustomer({...customer,requireTime: CommonUtil.anyTimeClick(3,customer)})}}>いつでも可</Button></Grid>
                    <Grid><Button style={{ margin :'2px', fontSize:9 }} variant="contained" disabled={!requireTimeChecked} onClick={() =>{ setCustomer({...customer,requireTime: CommonUtil.aroundTimeClick(3,customer)})}}>前後10分</Button></Grid>
                  </Grid>
                <Grid item container xs={12} alignItems="center" justifyContent="flex-start" sx={{marginTop:'1px'}}>
                  <Grid sx={{marginLeft:'7px'}}>金</Grid>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker 
                      value={customer.requireTime[4].startTime ? dayjs(customer.requireTime[4].startTime) : null} 
                      ampm={false} 
                      disabled={!requireTimeChecked}
                      slotProps={{ textField: { size: "small" } }}
                      sx={{ ".MuiInputBase-input": { height: 10 } , width: 100 , margin: 0.3}}
                      onChange={(newValue) => {
                        setCustomer({...customer,requireTime:CommonUtil.changeVisitTime(newValue,4,true,customer)})
                      }}
                    />
                    </LocalizationProvider>
                    <Grid>～</Grid>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker 
                      value={customer.requireTime[4].endTime ? dayjs(customer.requireTime[4].endTime) : null} 
                      ampm={false} 
                      disabled={!requireTimeChecked}
                      slotProps={{ textField: { size: "small" } }}
                      sx={{ ".MuiInputBase-input": { height: 10 } , width: 100 , margin: 0.3}}
                      onChange={(newValue) => {
                        setCustomer({...customer,requireTime:CommonUtil.changeVisitTime(newValue,4,false,customer)})
                      }}
                    />
                    </LocalizationProvider>
                    <Grid><Button style={{ fontSize:9 }} variant="contained" disabled={!requireTimeChecked}onClick={() =>{ setCustomer({...customer,requireTime: CommonUtil.anyTimeClick(4,customer)})}}>いつでも可</Button></Grid>
                    <Grid><Button style={{margin :'2px', fontSize:9 }} variant="contained" disabled={!requireTimeChecked} onClick={() =>{ setCustomer({...customer,requireTime: CommonUtil.aroundTimeClick(4,customer)})}}>前後10分</Button></Grid>
                  </Grid>
                <Grid item container xs={12} alignItems="center" justifyContent="flex-start" sx={{marginTop:'1px'}}>
                  <Grid sx={{marginLeft:'7px'}}>土</Grid>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker 
                      value={customer.requireTime[5].startTime ? dayjs(customer.requireTime[5].startTime) : null} 
                      ampm={false} 
                      disabled={!requireTimeChecked}
                      slotProps={{ textField: { size: "small" }}}
                      sx={{ ".MuiInputBase-input": { height: 10 } , width: 100 , margin: 0.3}}
                      onChange={(newValue) => {
                        setCustomer({...customer,requireTime:CommonUtil.changeVisitTime(newValue,5,true,customer)})
                      }}
                    />
                    </LocalizationProvider>
                    <Grid>～</Grid>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker 
                      value={customer.requireTime[5].endTime ? dayjs(customer.requireTime[5].endTime) : null} 
                      ampm={false} 
                      disabled={!requireTimeChecked}
                      slotProps={{ textField: { size: "small" } }}
                      sx={{ ".MuiInputBase-input": { height: 10 } , width: 100, margin: 0.3 }}
                      onChange={(newValue) => { 
                        setCustomer({...customer,requireTime:CommonUtil.changeVisitTime(newValue,5,false,customer)})
                      }}
                    />
                    </LocalizationProvider>
                    <Grid><Button style={{ fontSize:9 }} variant="contained" disabled={!requireTimeChecked}onClick={() =>{ setCustomer({...customer,requireTime: CommonUtil.anyTimeClick(5,customer)})}}>いつでも可</Button></Grid>
                    <Grid><Button style={{margin :'2px', fontSize:9 }} variant="contained" disabled={!requireTimeChecked} onClick={() =>{ setCustomer({...customer,requireTime: CommonUtil.aroundTimeClick(5,customer)})}}>前後10分</Button></Grid>
                  </Grid>
                </Grid>
              </Grid>
            <Grid item container alignItems="center" xs={12}>
            <Grid item xs={2} className='itemName'>色</Grid>
              <Grid item xs={10}>
                <RadioGroup
                    row
                    value={isOtherColor ? 'other' : customer.color}
                    onChange={(e) => { 
                      const value = e.target.value;
                      if (value === 'other') {
                        setCustomer({...customer,color: ''});
                        setIsOtherColor(true);
                      } else {
                        setCustomer({...customer,color: value});
                        setIsOtherColor(false);
                      }
                    }}
                >
                  <FormControlLabel label="■" control={<Radio />} value={Const.BlockColors.RED} sx={Const.RADIO_STYLE_RED}/>
                  <FormControlLabel label="■" control={<Radio />} value={Const.BlockColors.GREEN} sx={Const.RADIO_STYLE_GREEN}/>
                  <FormControlLabel label="■" control={<Radio />} value={Const.BlockColors.BLUE} sx={Const.RADIO_STYLE_BLUE}/>
                  <FormControlLabel label="■" control={<Radio />} value={Const.BlockColors.YELLOW} sx={Const.RADIO_STYLE_YELLOW}/>
                  <FormControlLabel label="■" control={<Radio />} value={Const.BlockColors.CYAN} sx={Const.RADIO_STYLE_CYAN}/>
                  <FormControlLabel label="■" control={<Radio />} value={Const.BlockColors.PINK} sx={Const.RADIO_STYLE_PINK}/>
                  <FormControlLabel label="色なし" control={<Radio />} value={Const.BlockColors.WHITE}/>
                  <FormControlLabel label="その他の色" control={<Radio />} value="other" />
                  <TextField
                    required
                    size="small"
                    defaultValue={defaultColor}
                    disabled={!isOtherColor}
                    onChange={(e) =>{
                      const value = e.target.value;
                      setCustomer({...customer,color: value})
                    }}
                    sx={{"& .MuiInputBase-input": { height: 18 },width: 150}}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">#</InputAdornment>
                      )
                    }}
                 />
                </RadioGroup>
              </Grid>
            </Grid>
            <Grid item container alignItems="center" xs={12}>
              <Grid item xs={2} className='itemName'>その他希望</Grid>
              <Grid item xs={10}>
                <TextField
                  value= {customer.others}
                  size="small"
                  sx={{ "& .MuiInputBase-input": { height: 15 }, width: 400 ,margin:'5px'}}
                  onChange={e => setCustomer({...customer,others:e.target.value})}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>キャンセル</Button>
        <Button type="button" onClick={handleFormSubmit}>OK</Button>
      </DialogActions>
    </Dialog>
  );
}

/**
 * 地図の中身コンポーネント(ダイアログ用)
 * @param props
 * @returns
 */
function MapBox(props) {
  const mapBox = React.useRef(null);
  const [map, setMap] = React.useState<google.maps.Map>();
  const [markerLatLng, setMarkerLatLng] = React.useState<{ lat: number; lng: number } | null>(null);
  const commonData = React.useContext(PublicParams);

  // 緯度経度が変更されたら markerLatLng を更新する
  React.useEffect(() => {
    setMarkerLatLng(props.children.props.position ?? null);
  }, [props.children.props.position]);
 
  // 初期地図の設定
  React.useEffect(() => {
    if (mapBox.current && !map && markerLatLng) {
      let options: google.maps.MapOptions;
      if(props.children.props.isAddDialog){
        options = {
          center: {
            lat: commonData.customerList[0].lat,
            lng: commonData.customerList[0].lng
          },
          zoom: Const.MAP_DEFAULT.ZOOM,
          styles: Const.MAP_DEFAULT.STYLES,
        };
      }else{
        options = {
          center: markerLatLng,
          zoom: Const.MAP_DEFAULT.ZOOM,
          styles: Const.MAP_DEFAULT.STYLES,
        };
      }
      setMap(new window.google.maps.Map(mapBox.current, options));
    }
  }, [mapBox, map, markerLatLng]);

  // markerLatLng が変更されたら地図の中心も更新する
  React.useEffect(() => {
    if (map && markerLatLng) {
      map.setCenter(markerLatLng);
    }
  }, [map, markerLatLng]);

  return (
    <>
      <div id="map-box" style={{ height: "50vh" }} ref={mapBox} />
      {/* ▼おまじない。これを入れる必要がある、と公式ドキュメントに書いてあった。*/}
      {React.Children.map(props.children, (child) => {
        if (React.isValidElement(child)) {
          //@ts-expect-error:set the map prop on the child component
          return React.cloneElement(child, { map });
        }
        return child;
      })}
      {/* ▲おまじないここまで */}
    </>
  );
}

/**
 * マーカーコンポーネント(ダイアログ用)
 * @param option
 * @returns
 */
function Marker(option) {
  const [marker, setMarker] = React.useState<google.maps.Marker>();

  React.useEffect(() => {
    //マーカー生成時の処理
    if (!marker) {
      const movedMarker = new window.google.maps.Marker(option);
      // ドラッグ＆ドロップで移動した時のイベント
      movedMarker.addListener('dragend', () => {
        const movedPosition = movedMarker.getPosition();
        if (movedPosition) {
          const latitude = movedPosition.lat();
          const longitude = movedPosition.lng();
          option.onDragEnd(latitude, longitude);
        }
      });
      setMarker(movedMarker);
    }

    //マーカーアンマウント時の処理
    return () => {
      if (marker) {
        marker.setMap(null); //マーカー削除
      }
    };
  }, [marker,option]);

  //マーカー生成時、またはoption(座標等)が変更された時の処理
  React.useEffect(() => {
    if (marker) {
      marker.setOptions(option);
    }
  }, [marker, option]);

  return null;
}