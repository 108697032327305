import * as React from "react";
import Grid from "@mui/material/Grid";
import { Button,TextField,Dialog,DialogActions,DialogContent,DialogTitle } from "@mui/material";
import { FormControlLabel } from "@mui/material";
import { Radio, RadioGroup } from "@mui/material";
import { PublicParams } from "../forms/MainForm.tsx";
import { Coordinate } from '../components/PlanTable.tsx'
import './CustomerDialog.css';
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from 'dayjs';
import * as Const from "../Const.tsx";
import { CommonUtil } from '../CommonUtil.tsx';
import * as Models from "../Models.tsx";

/**
 * 利用者追加ダイアログ
 * @returns
 */
export function ScheduleDialog({ isAddDialog }) { 

  //訪問計画(共通パラメータ)
  const commonData = React.useContext(PublicParams);

  let s;  //初期値のデータ
  let dialogTitle;
  let startTime;
  let endTime;

  if (isAddDialog) {
    dialogTitle = "スケジュール追加";
    if (!commonData.scheduleList) {
      commonData.scheduleList = [];
    }
    const contextMenu = React.useContext(Coordinate);
    //追加するスケジュールの初期のデータ
    s = CommonUtil.calculateClickData(commonData, contextMenu);
    if(s.startTime >= new Models.Time('17:45')){
      //開始時刻が17:45以降のケジュールは17:45開始にする
      startTime = new Models.Time('17:45');
    }else{
      startTime = s.startTime;
    }
    if(s.startTime > new Models.Time('17:30')){
      //終了時刻が18:00以降にならないようにする
      endTime = new Models.Time('18');
    }else{
      endTime = new Models.Time(s.endTime.toString());
      endTime.setMinutes(endTime.getMinutes() - 5);
    }
  }else{
    dialogTitle = "スケジュール編集";
    //選択中の編集するスケジュールを特定
    s = commonData.scheduleList.find( s => s.id === commonData.activeVisitIds[0].id);
  }

  /**
   * ダイアログを閉じる
   */
  const handleClose = () => {
    commonData.setDialogName(null);
  };

  const initialScheduleState = isAddDialog ? {
    id: null,
    day: s.day,
    staffCode: commonData.staffList[s.staffCode].code,
    arrivalTime: null,
    startTime: startTime,
    endTime: endTime,
    content: '',
    other: '',
  } : {
    id: s.id,
    day: s.day,
    staffCode: s.staffCode,
    arrivalTime: null,
    startTime: s.startTime,
    endTime: s.endTime,
    content: s.content,
    other: s.other,
  };

  const [schedule, setSchedule] = React.useState(initialScheduleState);
  //スケジュールが入れれる時間帯であるか
  const [isTimeError, setIsTimeError] = React.useState(false);
  const [isOverlapTime, setIsOverlapTime] = React.useState(false);
  const [isOverEndTime, setIsOverEndTime] = React.useState(false);
  const [isSameTime, setIsSameTime] = React.useState(false);

  React.useEffect(() => {
    const time = CommonUtil.changeScheduleTime(dayjs(schedule.endTime), false, commonData, schedule);
    setSchedule({
      ...schedule,
      startTime: time.startTime,
      endTime: time.endTime,
    });
    setIsTimeError(time.isTimeError);
    setIsOverlapTime(time.isOverlapTime);
  }, [isAddDialog, schedule.day, schedule.staffCode]);

  const handleFormSubmit = (e) => {
    
    if (schedule.content.trim() === '' || isTimeError || isOverlapTime || isOverEndTime || isSameTime) {
      e.preventDefault();
      alert('入力が正しくありません。');
      return;
    }

    const newSchedule : Models.Schedule = {
      id: isAddDialog ? commonData.scheduleList.length + 1 : schedule.id,
      day: schedule.day,
      staffCode: schedule.staffCode,
      arrivalTime: null,
      startTime: Models.Time.createTime(schedule.startTime),
      endTime: Models.Time.createTime(schedule.endTime),
      content: schedule.content,
      other: schedule.other,
    };

    if (isAddDialog) {
      const updatedScheduleList = [...commonData.scheduleList, newSchedule];
      commonData.setScheduleList(updatedScheduleList);
    } else {

      const updatedScheduleList = commonData.scheduleList.map(s => {
        if (s.id === newSchedule.id) {
          return { ...newSchedule };
        }else {
          return s;
        }
      });
      commonData.setScheduleList(updatedScheduleList);
    }
    handleClose();
  };

  return (
    <Dialog
      maxWidth='sm'
      open={isAddDialog ? commonData.dialogName === Const.DialogNames.ADD_SCHEDULE_DIALOG : commonData.dialogName === Const.DialogNames.SCHEDULE_DIALOG}
      PaperProps={{
        component: 'form',
        onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
          event.preventDefault();
          handleClose();
        },
      }}
    >
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item container xs={12} sx={{padding: '10px'}} alignItems="center">
            <Grid item xs={2} className='itemName' sx={{paddingRight: '30px'}} >曜日</Grid>
            <Grid item xs={10}>
                <RadioGroup row value={schedule.day} onChange={e =>setSchedule({...schedule, day:parseInt(e.target.value)})}>
                    <FormControlLabel label="月" control={<Radio />} value="0" />
                    <FormControlLabel label="火" control={<Radio />} value="1" />
                    <FormControlLabel label="水" control={<Radio />} value="2" />
                    <FormControlLabel label="木" control={<Radio />} value="3" />
                    <FormControlLabel label="金" control={<Radio />} value="4" />
                    <FormControlLabel label="土" control={<Radio />} value="5" />
                </RadioGroup>
            </Grid>
            <Grid item xs={2} className='itemName' sx={{paddingRight: '30px'}} >スタッフ</Grid>
            <Grid item xs={10}>
            <RadioGroup row value={schedule.staffCode} onChange={e =>setSchedule({...schedule, staffCode:parseInt(e.target.value)})}>
              {commonData.staffList.map((staff, index) => {
                const firstName = staff.name.split(' ')[0];
                return (
                  <FormControlLabel
                    key={index}
                    label={firstName}
                    control={<Radio />}
                    value={staff.code}
                  />
                );
               })}
            </RadioGroup>
            </Grid>
            <Grid container alignItems="center">
            <Grid item xs={2} className='itemName' sx={{paddingRight: '30px'}}>時刻</Grid>
              <Grid item xs={10} sx={{display: 'flex', alignItems: 'center'}}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker 
                    timeSteps={{ minutes: 15 }}
                    minTime={dayjs(new Models.Time('08'))}
                    maxTime={dayjs(new Models.Time('17:45'))}
                    value={dayjs(schedule.startTime)} 
                    ampm={false} 
                    slotProps={{
                      textField: {
                        size: 'small',
                        inputProps: {
                          readOnly: true, // 手入力を無効化する
                        },
                        sx: { "& .MuiInputBase-input": { height: 10 }, width: 100, margin: 0.3 },
                      },
                    }}
                    onChange={(newValue) => {
                      const time = CommonUtil.changeScheduleTime(newValue,true,commonData,schedule); 
                      setSchedule({
                        ...schedule,
                        startTime: time.startTime,
                        endTime: time.endTime,
                      });
                      setIsTimeError(time.isTimeError)
                      setIsOverlapTime(time.isOverlapTime)
                      if(time.startTime.getTime() === time.endTime.getTime()){
                        setIsSameTime(true);
                      }else{
                        setIsSameTime(false);
                      }
                    }}
                  />
                </LocalizationProvider>
                <Grid>～</Grid>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker 
                    timeSteps={{ minutes: 15 }}
                    minTime={dayjs(new Models.Time('08'))}
                    maxTime={dayjs(new Models.Time('18'))}
                    value={dayjs(schedule.endTime)} 
                    ampm={false} 
                    slotProps={{
                      textField: {
                        size: 'small',
                        inputProps: {
                          readOnly: true, // 手入力を無効化する
                        },
                        sx: { "& .MuiInputBase-input": { height: 10 }, width: 100, margin: 0.3 },
                      },
                    }}
                    onChange={(newValue) => {
                      const time = CommonUtil.changeScheduleTime(newValue,false,commonData,schedule); 
                      if(dayjs(new Models.Time('18')) < newValue){
                        //終了時刻が18:00以降の場合
                        setIsOverEndTime(true);
                        setIsTimeError(time.isTimeError)
                        setIsOverlapTime(time.isOverlapTime)
                        return;
                      }
                      setSchedule({
                        ...schedule,
                        startTime: time.startTime,
                        endTime: time.endTime,
                      });
                      setIsTimeError(time.isTimeError)
                      setIsOverlapTime(time.isOverlapTime)
                      setIsOverEndTime(false);
                      if(time.startTime.getTime() === time.endTime.getTime()){
                        setIsSameTime(true);
                      }else{
                        setIsSameTime(false);
                      }
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={2}></Grid>
              <Grid item xs={10}>
              {isTimeError && (
                  <div style={{ color: 'red', fontSize: 9, marginLeft: 10 }}>
                    ・終了時刻が開始時刻よりも早いです。
                  </div>
                )}
              {isOverlapTime && (
                  <div style={{ color: 'red', fontSize: 9, marginLeft: 10 }}>
                    ・既に予定が入っています。
                  </div>
                )}  
               {isOverEndTime && (
                  <div style={{ color: 'red', fontSize: 9, marginLeft: 10 }}>
                    ・18時以降の予定は入れられません。
                  </div>
                )} 
                {isSameTime && (
                  <div style={{ color: 'red', fontSize: 9, marginLeft: 10 }}>
                    ・開始時刻と終了時刻が同じです。
                  </div>
                )}  
              </Grid>
            </Grid>
            <Grid item xs={2} className='itemName' sx={{paddingRight: '30px'}}>内容</Grid>
            <Grid item xs={10}>
                <TextField
                    required
                    id="others"
                    size="small"
                    value={schedule.content}
                    sx={{ "& .MuiInputBase-input": { height: 15 }, width: 400 ,margin:'5px'}}
                    onKeyDown={(event) => {
                      if(event.key === 'Enter'){
                        event.preventDefault();
                      }
                    }}
                    onChange={e =>setSchedule({...schedule, content:e.target.value})}
                />    
            </Grid>
            <Grid item xs={2} className='itemName' sx={{paddingRight: '30px'}}>備考</Grid>
            <Grid item xs={10}>
                <TextField
                    id="others"
                    size="small"
                    value={schedule.other}
                    sx={{ "& .MuiInputBase-input": { height: 60 }, width: 400 ,margin:'5px'}}
                    onChange={e =>setSchedule({...schedule, other:e.target.value})}
                    multiline
                    rows={4}
                />   
            </Grid>
            </Grid>
         </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>キャンセル</Button>
        <Button type="submit"  onClick={handleFormSubmit}
        >OK</Button>
      </DialogActions>
    </Dialog>
    );
  }