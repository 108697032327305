import React from 'react';
import { styled } from '@mui/material/styles';
import { AppBar } from '@mui/material';
import { Toolbar } from '@mui/material';
import { Typography } from '@mui/material';
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem';
import * as Sample from '../Sample.tsx';
import * as ExportExcelFile from '../forms/ExportExcelFile.tsx';
import * as Const from '../Const.tsx';

const PREFIX = 'Header';
const classes = {
  menuButton: `${PREFIX}-menuButton`,
  title: `${PREFIX}-title`,
  toolbar: `${PREFIX}-toolbar`,
}
const Root = styled('div')(({ theme }) => ({
  [`&.${classes.menuButton}`]: {
    marginRight: theme.spacing(2),
  },
  [`& .${classes.title}`]: {
    flexGrow: 1,
  },
  [`& .${classes.toolbar}`]: {
    minHeight: `${Const.LAYOUT.HEADER_HEIGHT}px`, // minHeight を再設定する
    backgroundColor: '#3f51b5'
  },
}));

/***
 * 画面ヘッダー
 * @param setCommonData 共通データのsetter
 */
export function Header({commonData, setCommonData}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };
  const download =  () =>{
    ExportExcelFile.writeExcelFile(commonData).then(closeMenu);
  }
  const setSampleData = () =>{
    setCommonData(Sample.getSampleCommonData());  
    closeMenu();
  }
  const jumpToDropForm = () =>{
    setCommonData(null);
    closeMenu();
  }

  return (
    <Root>
      <AppBar style={{height: `${Const.LAYOUT.HEADER_HEIGHT}px`}} position="static">
      <Toolbar className={classes.toolbar}>
        <Typography variant="h6" className={classes.title}>
          訪問計画ビューア
        </Typography>
        <IconButton 
          edge="end"
          className={classes.menuButton}
          color="inherit"
          aria-label="menu"
          onClick={openMenu}
          >
          <MenuIcon/>
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={closeMenu}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          {commonData == null ? <MenuItem onClick={setSampleData}>サンプルデータ表示</MenuItem> : ''}
          {commonData != null ? <MenuItem onClick={jumpToDropForm}>ファイル取込</MenuItem> : ''}
          {commonData != null ? <MenuItem onClick={download}>ダウンロード</MenuItem> : ''}
        </Menu>
      </Toolbar>
    </AppBar>
    </Root>
  );
}