import './PlanTable.css';
import * as Models from '../Models.tsx';
import {Menu, MenuItem} from '@mui/material';
import * as React from 'react';
import Draggable from 'react-draggable';
import {PublicParams, PublicCaches} from '../forms/MainForm.tsx';
import * as Const from '../Const.tsx';
import { CommonUtil } from '../CommonUtil.tsx';
import '../DateExtensions.tsx';
import {detectAlert} from './PlanTable.tsx';

/**
 * スタッフスケジュールブロックのコンポーネント
 * @param props.schedule スタッフスケジュール
 * @param props.setSchedule スタッフスケジュールのsetter
 * @param props.setVisit 訪問のsetter(位置調整された訪問ブロックの警告更新で使う)
 * @param props.index commonData.staffScheduleの中で何番目の訪問情報か？
 * @param props.key key:一意キー(なくても動くけど、つけることでパフォーマンスが向上する)
 * @returns 
 */
export function StaffSchedule(
      props : {schedule : Models.Schedule, setSchedule : (schedule: Models.Schedule, index: number) => void ,
      index : number, key : number, position:{x: number, y: number}}){
    
    //共通データ
    const commonData = React.useContext(PublicParams);
    const cacheData = React.useContext(PublicCaches);
  
    const nodeRef = React.useRef(null); 
    const rowHeight = Const.LAYOUT.TIMELINE_CELL_HEIGHT; //セル1マス分の高さ
    const colWidth = Const.LAYOUT.TIMELINE_CELL_WIDTH;   //セル1マス分の幅
    const dayBorder = 1;
    const startDay = 0; //月曜始まり
    const startHour = Const.TIMELINE_START_HOUR; //タイムライン開始時刻(例 8時)
    const endHour = Const.TIMELINE_END_HOUR;    //タイムライン終了時刻(例 18時)
  
    const dayWidth = colWidth * commonData.staffList.length + dayBorder; //1日分の幅
  
    let dayCol;   //何曜日か(月曜が0)
    let staffCol; //左から何人目の職員か(0始まり)
    let timeRow;  //上から何行目か(0始まり)
  
    /////////////////////////////////////////////
    // 開始時刻と終了時刻からブロックの高さを計算
    /////////////////////////////////////////////
    let height;
    const diffTime = props.schedule.endTime.setFullYear(2000, 0, 1) - props.schedule.startTime.setFullYear(2000, 0, 1);
    const diffMinutes = (diffTime / (1000 * 60 ));
    const diff = (diffMinutes / 15)  * rowHeight ;
    
    if(diff < 21){
      height = 21;
    }else{
      height = diff;
    }
  
    /////////////////////////////////////////////
    // ドラッグイベント
    /////////////////////////////////////////////
  
    const [dragging, setDragging] = React.useState(false); //ドラッグ中か
    const [position, setPosition] = React.useState(props.position); //現在のDraggableブロックの座標(ドラッグの度に値が更新される)

    //ドラッグ前のprops.visitの状態
    const [prevSchedulePosition, setPrevSchedulePosition] = React.useState(null);

    React.useEffect(()=>{
      //scheduleの変更があるたびに訪問先ブロックの位置を更新する
      setPosition(props.position);
     },[props.setSchedule])
  
    //ドラッグ開始時のイベントハンドラ
    const handleStart = ()=>{
      //ドラッグ前の状態
      setPrevSchedulePosition({ ...props.position });
    }
  
    //ドラッグ時のイベントハンドラ
    const handleDrag = (e, obj) => {
      const { x, y } = obj;
      setPosition({ x, y });
      setDragging(true);
    }
  
    //ドラッグ終了時のイベントハンドラ 
    const handleStop = (e, obj)=>{
      setDragging(false);
  
      let fittedX;
      let fittedY;
  
      //移動後の時間などを格納する変数
      let newStartTime = props.schedule.startTime;
      let newArrivalTime = props.schedule.arrivalTime;
      let newDay = props.schedule.day;
      let newStaffCode = props.schedule.staffCode;
      let newEndTime = props.schedule.endTime; 

      //座標からセル位置を計算
      const x_ = obj.x + colWidth /2; //ブロックの中心のX座標
      const day = Math.floor(x_ / dayWidth);   
      const xInDay = x_ % dayWidth;
      const staff = xInDay < dayWidth - dayBorder ?  Math.floor(xInDay / colWidth) : commonData.staffList.length  - 1; 
      const row = Math.floor(obj.y / rowHeight);
      dayCol = day;
      staffCol = staff;
      timeRow = row;

      //セル位置から時刻を計算
      const startTimeH = Math.floor(row / 4) + startHour
      const startTimeM = (row % 4) * 15;
      newStartTime = new Models.Time(('0' + startTimeH ).slice( -2 ) + ":" + ('0' + startTimeM ).slice( -2 ));

      //セル位置から曜日とスタッフを計算
      newDay = day + startDay;
      newStaffCode= commonData.staffList[staff].code;

      //(タイムラインで)1つ上の訪問先を取得
      const earlierVisitList = commonData.visitList
                              .filter( v => v.day == newDay && v.staffCode ==  newStaffCode && v.startTime <= newStartTime);
      const earlierScheduleList = commonData.scheduleList
                              .filter( s => s.id != props.schedule.id && s.day == newDay && s.staffCode ==  newStaffCode && s.startTime <= newStartTime);
      const earlierList:Models.VisitBase[] = [...earlierVisitList, ...earlierScheduleList].sort( (v,w) => w.startTime.getTime() - v.startTime.getTime());
      const previousVisit = earlierList.length > 0 ? earlierList[0] : null;

      //(タイムラインで)1つ下の訪問先を取得
      const laterVisitList = commonData.visitList
                              .filter( v => v.day == newDay && v.staffCode ==  newStaffCode && v.startTime > newStartTime);
      const laterScheduleList = commonData.scheduleList
                              .filter( v => v.id != props.schedule.id && v.day == newDay && v.staffCode ==  newStaffCode && v.startTime > newStartTime);
      const laterList:Models.VisitBase[] = [...laterVisitList, ...laterScheduleList].sort( (v,w) => v.startTime.getTime() - w.startTime.getTime());
      const nextVisit = laterList.length > 0 ? laterList[0] : null;

      //ブロック位置の自動調整
      const autoPositioning = async function(){        
        //ブロックをずらすことができるか
        let canMove = true;

        //////////////////////////////////////
        // Above
        // 自分自身の訪問先ブロックを下にずらす
        //////////////////////////////////////

        //ずらす先の時間
        //1つ上の訪問先がある場合は1つ上の終了時刻、ない場合はマウスアップした時刻を格納
        const calculatedStartTime : Date = previousVisit != null ? new Date(previousVisit.endTime): new Date(newStartTime);

        //「ドラッグでマウスアップした時刻」と「ずらす先の時間」を比較
        let carryUpMinutes_A = 0; //ずらす時間(分) //AはAbove(上)の頭文字
        let shiftY_A =0; //ずらす位置Y(px)
        if(newStartTime < calculatedStartTime){ 
            //「ずらす先の時間」の方が遅い場合は、その分ブロックを下にずらす
            carryUpMinutes_A = (calculatedStartTime.getTime() - newStartTime.getTime()) / (60*1000);
            newStartTime.addMinutes(carryUpMinutes_A);
            //セルの位置計算
            shiftY_A = Math.floor(carryUpMinutes_A * rowHeight / 15); //15で割れるはずだが念のためfloorしておく
            //到着時刻=開始時刻とする
            newArrivalTime = Models.Time.createTime(newStartTime);
        }

        //開始時刻から終了時刻を計算
        newEndTime = Models.Time.createTime(newStartTime);
        newEndTime.addMinutes(diffMinutes);

        //タイムラインの終端をはみ出さないかチェック
        if(obj.x >= dayWidth * Const.WORK_DAY_NUM - (colWidth / 2)){
          canMove = false;
        }
        if(obj.y <= 0){
          canMove = false;
        }
        if(new Models.Time('18') < newEndTime){
          canMove = false;
        }

        //////////////////////////////////////
        // Below
        // 後続の訪問先ブロックを下にずらす
        //////////////////////////////////////

        //ずらす先の時間
        //スケジュールの終了時刻にする
        const calculatedNextTime : Date = new Date(newEndTime);

        //「ずらす先の時間」と「元々設置してある1つ下の訪問先の開始時刻」を比較
        let carryUpMinutes_B = 0; //ずらす時間(分) //BはBelow(下)の頭文字
        if(nextVisit != null && nextVisit.startTime < calculatedNextTime){ 
            //「ずらす先の時間」の方が遅い場合は、その分ブロックを下にずらす
            carryUpMinutes_B = (calculatedNextTime.getTime() - nextVisit.startTime.getTime()) / (60*1000);
        }

        //タイムラインの終端をはみ出さないかチェック
        if(nextVisit != null){
          const lastVisit = laterList[laterList.length - 1];
          const calculatedLastTime = new Date(lastVisit.startTime);
          const stayTime = lastVisit.endTime.getTime() - lastVisit.startTime.getTime();
          calculatedLastTime.addMinutes((stayTime  / (60*1000)) + carryUpMinutes_B);
          if( endHour < calculatedLastTime.getHours() + calculatedLastTime.getMinutes() / 60){
            alert("タイムラインの時間外にはみ出すため移動できません。");
            canMove = false;
          }
        }

        //移動先の下に2人訪問がいた場合のチェック
        if(nextVisit != null && canMove && carryUpMinutes_B > 0){
          //2人訪問のブロックをselect
          const laterTwoStaffList = laterVisitList
          .filter( v => v.partnerVisitId != null)
          .sort( (v,w) => v.startTime.getTime() - w.startTime.getTime());

          for(let i=0; i< laterTwoStaffList.length; i++){
            //2人訪問の相方を取得
            const partnerVisit = commonData.visitList.find(v => v.id == laterTwoStaffList[i].partnerVisitId);
            //相方の下にいる訪問先を取得
            const partnerLaterVisitList = commonData.visitList
                .filter( v => v.id != partnerVisit.id && v.day == partnerVisit.day && v.staffCode ==  partnerVisit.staffCode && v.startTime >= partnerVisit.startTime)
                .sort( (v,w) => v.startTime.getTime() - w.startTime.getTime());

            const partnerLaterScheduleList = commonData.scheduleList
                .filter( s => s.id != props.schedule.id && s.id != partnerVisit.id && s.day == partnerVisit.day && s.staffCode ==  partnerVisit.staffCode && s.startTime >= partnerVisit.startTime)
                .sort( (v,w) => v.startTime.getTime() - w.startTime.getTime());   

            const partnerLaterList = [...partnerLaterVisitList,...partnerLaterScheduleList]
                .sort( (v,w) => v.startTime.getTime() - w.startTime.getTime()); 
                
            //相方の下に訪問先がいる場合、以下の条件を満たしていればOK
            //後続の訪問に2人訪問がない & 最終訪問先がタイムラインの終端をはみ出さない
            if(partnerLaterList.length > 0){
              //後続の訪問に2人訪問がいないか調べる
              //(ただし相方が自分自身の場合は2人訪問から除外する)
              const partnerLaterList_2 = partnerLaterList
                .filter( v => ("partnerVisitId" in v && v.partnerVisitId != null)) //相方が存在
                .filter( v => ("partnerVisitId" in v && !laterVisitList.some( w => w.id == v.partnerVisitId ))); //相方が自分自身でない
              const existsTwoVisit = partnerLaterList_2.length > 0;
              if(existsTwoVisit){
                alert("2人訪問が複数箇所で入り組んでいるため移動判定ができません。\n申し訳ございませんが、別の場所への移動をお願いします。")
                canMove = false;
                break;
              }
              //最終訪問先がタイムラインの終端をはみ出さないか調べる   
              const newLastTime = new Date(partnerLaterList[partnerLaterList.length -1].startTime);
              newLastTime.addMinutes(Const.DEFAULT_STAY_MINUTES);
              newLastTime.addMinutes(carryUpMinutes_B);
              if(endHour < newLastTime.getHours() + newLastTime.getMinutes() / 60){
                alert("2人訪問の相方のタイムラインが時間外にはみ出すため移動できません。")
                canMove = false;
                break;
              }
            }
          }
        }

        //動かす先の座標
        let targetX: number, targetY: number;

        //ブロックをずらせる場合
        if(canMove){
          //今回の移動で影響があるコース
          const dependencyCourse: {day : number, staffCode: number}[] =[];

          //開始時刻、終了時刻にセット
          //(commonData.visitListにセットするが、setter関数は後で通す)
          const current = commonData.scheduleList.find(s => s.id == props.schedule.id);
          current.arrivalTime = newArrivalTime;
          current.startTime = newStartTime;
          current.endTime = newEndTime;
          const shiftedStaffs:number[] = []; //ずらしが完了したstaff(forループで重複してずれるのを避けるため)
          for(let i =0; i < laterList.length; i++){
            laterList[i].arrivalTime?.addMinutes(carryUpMinutes_B);
            laterList[i].startTime.addMinutes(carryUpMinutes_B);
            laterList[i].endTime.addMinutes(carryUpMinutes_B);
            //相方
            if("partnerVisitId" in laterList[i]){
              const partner = commonData.visitList.find(v => v.id == (laterList[i] as Models.Visit).partnerVisitId);
              if(partner != undefined && !shiftedStaffs.includes(partner.staffCode)){
                const partnerStartTimeOrg = Models.Time.createTime(partner.startTime); //ずらす前の開始時刻
                partner.startTime = Models.Time.createTime(laterList[i].startTime);
                partner.endTime = Models.Time.createTime(laterList[i].endTime);
                //相方の到着時刻計算
                const earlierVisitList = commonData.visitList
                            .filter( v => v.id != partner.id && v.day == partner.day && v.staffCode ==  partner.staffCode && v.startTime <= partner.startTime );
                const earlierScheduleList = commonData.scheduleList
                            .filter( s => s.day == partner.day && s.staffCode ==  partner.staffCode && s.startTime <= partner.startTime);
                const earlierList:Models.VisitBase[] = [...earlierVisitList, ...earlierScheduleList].sort( (v,w) => w.startTime.getTime() - v.startTime.getTime());
                const previousVisit = earlierList.length > 0 ? earlierList[0] : null; 
                if(previousVisit == null){
                  //1つ前のブロックが存在しない場合、開始時刻が到着時刻
                  partner.arrivalTime = Models.Time.createTime(partner.startTime);
                }else {
                  //1つ前のブロックがある場合、1つ前の終了時刻+移動時間 が到着時刻
                  const {travelMinutesAbove} = await CommonUtil.fetchTravelMinutes(previousVisit, partner, null, commonData, cacheData);
                  partner.arrivalTime  = Models.Time.createTime(previousVisit.endTime);
                  partner.arrivalTime.addMinutes(travelMinutesAbove);
                }

                //相方の後続の訪問先をずらす
                const partnerLaterVisitList = commonData.visitList
                          .filter( v => v.id != partner.id && v.day == partner.day && v.staffCode == partner.staffCode && v.startTime > partnerStartTimeOrg);
                const partnerLaterScheduleList = commonData.scheduleList
                          .filter( s => s.day == partner.day && s.staffCode == partner.staffCode && s.startTime > partner.startTime);
                const partnerLaterList = [...partnerLaterVisitList, ...partnerLaterScheduleList];
                partnerLaterList.sort((v,w) => v.startTime.getTime() - w.startTime.getTime());
                for(let j=0; j<partnerLaterList.length; j++){
                  partnerLaterList[j].arrivalTime?.addMinutes(carryUpMinutes_B);
                  partnerLaterList[j].startTime.addMinutes(carryUpMinutes_B);
                  partnerLaterList[j].endTime.addMinutes(carryUpMinutes_B);
                }

                //「ずらし」完了
                shiftedStaffs.push(partner.staffCode);

                dependencyCourse.push({day: partner.day, staffCode:partner.staffCode});
              }
            }
          }
          
          //曜日スタッフをpropsにセット
          props.schedule.day = day + startDay;
          props.schedule.staffCode = commonData.staffList[staffCol].code;

          //警告アイコン判定
          refreshAlert(laterVisitList) 

          //ブロックをGridにフィットさせる
          fittedX = day * dayWidth + staff * colWidth;
          fittedY = row * rowHeight + shiftY_A; 

          //commonDataにset
          commonData.setVisitList(commonData.visitList);
          commonData.setScheduleList([...commonData.scheduleList]);

          //影響があるコースは経路情報を削除する
          commonData.setDirectionsList(commonData.directionsList.filter(x => !dependencyCourse.some( c => c.day == x.day && c.staffCode == x.staffCode)));

          targetX = fittedX;
          targetY = fittedY;
        }else{
          //ずらせない場合はドラッグ前に戻す
          targetX = prevSchedulePosition.x;
          targetY = prevSchedulePosition.y;
        }

        //10ミリ秒後にフィットさせる。
        //(直ちにフィットさせると,素早くドラッグしたときにreact-draggableがtranslateを上書きしてしまうので上手くいかない)
        //(いろいろ試したが、これしか方法が思いつかなかった)
        setTimeout(() => {
          obj.node.style.transform = `translate( ${targetX}px,  ${targetY}px)`;
          setPosition({x:targetX, y:targetY});
        }, 10);
      } //autoPositioning
      autoPositioning();
    }

    //スケジュールをドラッグで移動した直後、影響して時間が変わった訪問先の警告メッセージを更新する
    const refreshAlert = function(dependencyVisits: Models.Visit[]){
      //影響がある訪問先ブロックの警告を再検査
      dependencyVisits.forEach(visit => {
        const customer = commonData.customerList.find( c => c.code === visit.customerCode);
        if(customer != null){
          const alertSet: Models.AlertSet = detectAlert({visit: visit, customer: customer}, commonData);
          //警告をセット
          visit.warning = alertSet.warningList;
          visit.alert = alertSet.alertList;
          const idx = commonData.visitList.findIndex( v => v.id === visit.id);
          commonData.visitList = [
            ...commonData.visitList.slice(0, idx),
            {...commonData.visitList[idx], alert:visit.alert, warning:visit.warning},
            ...commonData.visitList.slice(idx + 1)];
        }
      });
    }
  
    /////////////////////////////////////////////
    // マウスダウンイベント
    /////////////////////////////////////////////
    const handleMouseDown = ()=>{ 
      commonData.setActiveVisitIds([{type:Const.ActiveBlockTypes.SCHEDULE, id:props.schedule.id}]);
    }
  
    /////////////////////////////////////////////
    // 右クリックメニュー
    /////////////////////////////////////////////
    const [contextMenu, setContextMenu] = React.useState<Models.ContextMenuPosition | null>(null);
  
    const handleContextMenuOpen = (e) => {
      e.preventDefault();
      if(contextMenu === null){
        setContextMenu({
          mouseX: e.clientX + 2,
          mouseY: e.clientY - 6,
        });
      }else{
        setContextMenu(null);
      }
    };
  
    const handleContextMenuClose = () => {
      setContextMenu(null);
    };
  
    const handleOpenScheduleDialog = () => {
      commonData.setDialogName(Const.DialogNames.SCHEDULE_DIALOG);
      handleContextMenuClose();
    };
  
    const handleDeleteShedule = () => {
      setContextMenu(null);
      if(confirm("スケジュールを削除します。よろしいですか？")){
      commonData.setActiveVisitIds([]);
      //スケジュール(schedule)
      commonData.setScheduleList(commonData.scheduleList.filter(s => s.id != props.schedule.id));
      }
    };
  
    const isActive = (commonData.activeVisitIds.length > 0 
      && commonData.activeVisitIds[0].type === Const.ActiveBlockTypes.SCHEDULE && props.schedule.id === commonData.activeVisitIds[0].id);
    
    return(
      <div onContextMenu={handleContextMenuOpen}>
        <Draggable
          onStart={handleStart}
          onDrag={handleDrag}
          onStop={handleStop}
          onMouseDown={handleMouseDown}
          position={position}
          bounds={{left:0, top:0, right: dayWidth * Const.WORK_DAY_NUM - (colWidth / 2), bottom: rowHeight * 6 * 10}}
          nodeRef={nodeRef}>
        <div 
          ref={nodeRef} 
          className={"schedule" + (dragging ? ' dragging-visit' : '') + (isActive ? ' active-schedule ' : '')}
          style={{height : height }}
          >
            <span style={{fontSize:'x-small'}}>{(props.schedule.startTime ? props.schedule.startTime.toString() : '\u00A0')}</span>
            <div style={{lineHeight:'1em'}}>{props.schedule.content}</div>
        </div> 
        </Draggable>
        <Menu
          open={contextMenu !== null}
          onClose={handleContextMenuClose}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu !== null
              ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
              : undefined
          }
        >
          <MenuItem onClick={handleOpenScheduleDialog}>スケジュール修正</MenuItem>
          <MenuItem onClick={handleDeleteShedule}>スケジュール削除</MenuItem>
        </Menu> 
      </div>
    )
  }
  